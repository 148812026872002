import React, { useMemo } from 'react';

interface ISkeletonLine {
    height?: 'small' | 'large' | number;
}

export const SkeletonLine: React.FC<ISkeletonLine> = (props: ISkeletonLine) => {
    const { height = 'small' as ISkeletonLine['height'] } = props;

    const styleHeight = useMemo((): number => {
        if (typeof height === 'number') {
            return height;
        }

        if (height === 'small') {
            return 10;
        }

        if (height === 'large') {
            return 32;
        }

        return 10;
    }, [height]);

    return (
        <div
            className={`relative block w-full animate-pulse bg-neutral-500/10`}
            style={{ height: `${styleHeight}px` }}
        />
    );
};
