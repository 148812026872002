import { useEffect } from 'react';

export const useKeyListener = (key: string, callback: () => void) => {
    // Listen for ESC key press to close the modal
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === key) {
                callback();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [callback, key]); // Depend on closeFunction to avoid re-adding the listener unnecessarily
};
