import React, { PropsWithChildren } from 'react';

interface ICardWrapper extends PropsWithChildren {}

export const CardWrapper: React.FC<ICardWrapper> = (props) => {
    const { children } = props;

    return (
        <div className="relative flex flex-col gap-2 lg:grid lg:grid-cols-2 lg:gap-4">
            {children}
        </div>
    );
};
