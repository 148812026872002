import { useEffect, useMemo, useState } from 'react';

export const useEnvironment = () => {
    const simulateNonDevelopment = false;

    const [hostname, setHostname] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHostname(window.location.hostname);
        }
    }, []);

    const isDevelopment = useMemo(() => {
        return hostname.includes('localhost') && !simulateNonDevelopment;
    }, [hostname, simulateNonDevelopment]);

    return { hostname, isDevelopment };
};
