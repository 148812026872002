import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { imagePathSanitizer } from '../../../../helper/imagePathSanitizer';

interface IFavIconTenant {}

export const FavIconTenant: React.FC<IFavIconTenant> = () => {
    const tenantTheme = useRecoilValue(getTenantTheme);

    const documentTitle: string = useMemo(() => {
        let title: string;

        if (tenantTheme.brand.pageTitle && tenantTheme.brand.pageTitle.length > 0) {
            title = tenantTheme.brand.pageTitle;
        } else {
            title = tenantTheme.brand.companyName ?? 'Cloudbar';
        }

        return title;
    }, [tenantTheme.brand.companyName, tenantTheme.brand.pageTitle]);

    useEffect(() => {
        if (tenantTheme.brand.logo) {
            // Update the document documentTitle
            document.title = documentTitle;

            let iconUrl = '';

            if (tenantTheme.brand?.logo?.url) {
                iconUrl = imagePathSanitizer(tenantTheme.brand.logo.url);
            }

            if (tenantTheme.brand?.favicon?.url) {
                iconUrl = imagePathSanitizer(tenantTheme.brand.favicon.url);
            }

            if (iconUrl === '') {
                return;
            }

            const head = document.head;
            const link = document.createElement('link');
            link.type = 'image/jpg';
            link.rel = 'shortcut icon';
            link.href = iconUrl;
            head.appendChild(link);

            return () => {
                head.removeChild(link);
            };
        }
    }, [documentTitle, tenantTheme]);

    return <></>;
};
