import React, { PropsWithChildren, useMemo } from 'react';
import { nanoid } from 'nanoid';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';

export interface IHeadline extends PropsWithChildren {
    headline?: string;
    headlineSpan?: React.ReactNode;
    type: 'h1' | 'h4' | 'h5' | 'h6' | 'h1-responsive' | 'h4-responsive';
    testId?: string;
    translationKey?: string;
}

export const Headline: React.FC<IHeadline> = (props) => {
    const {
        headline,
        type,
        testId,
        translationKey,
        headlineSpan: headlineSpanProp,
        children,
    } = props;
    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const headlineSpan = useMemo(() => {
        if (!headline && headline === '') return null;

        if (headlineSpanProp) return headlineSpanProp;
        return (
            <span
                {...applyTranslation({
                    key: translationKey,
                })}
            >
                {(translationKey && getT(translationKey)) ?? headline ?? ''}
            </span>
        );
    }, [applyTranslation, getT, headline, headlineSpanProp, translationKey]);

    return (
        <>
            {type === 'h1' && (
                <h1
                    className="text-2xl font-bold lg:text-3xl"
                    data-test-id={`${testId ?? nanoid()}-headlineH1`}
                    style={{
                        color: tenantTheme.colors.primary.DEFAULT,
                    }}
                >
                    {children}
                    {headlineSpan}
                </h1>
            )}
            {type === 'h4' && (
                <h4
                    className="text-2xl font-semibold"
                    data-test-id={`${testId ?? nanoid()}-headlineH4`}
                    style={{
                        color: tenantTheme.colors.primary.DEFAULT,
                    }}
                >
                    {children}
                    {headlineSpan}
                </h4>
            )}
            {type === 'h5' && (
                <h5
                    className="text-lg font-semibold"
                    data-test-id={`${testId ?? nanoid()}-headlineH5`}
                    style={{ color: tenantTheme.colors.primary.DEFAULT }}
                >
                    {children}
                    {headlineSpan}
                </h5>
            )}
            {type === 'h6' && (
                <h6
                    className="text-base font-semibold"
                    data-test-id={`${testId ?? nanoid()}-headlineH6`}
                    style={{ color: tenantTheme.colors.primary.DEFAULT }}
                >
                    {children}
                    {headlineSpan}
                </h6>
            )}
            {type === 'h1-responsive' && (
                <h1
                    className="text-lg font-semibold sm:font-bold sm:text-xl md:text-2xl lg:text-3xl"
                    data-test-id={`${testId ?? nanoid()}-headline-H1-Responsive`}
                    style={{ color: tenantTheme.colors.primary.DEFAULT }}
                >
                    {children}
                    {headlineSpan}
                </h1>
            )}
            {type === 'h4-responsive' && (
                <h4
                    className="text-base font-semibold sm:text-lg md:text-xl lg:text-2xl"
                    data-test-id={`${testId ?? nanoid()}-headline-H4-Responsive`}
                    style={{ color: tenantTheme.colors.primary.DEFAULT }}
                >
                    {children}
                    {headlineSpan}
                </h4>
            )}
        </>
    );
};
