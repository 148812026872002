import { Transition } from '@headlessui/react';
import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';
import { Headline } from '../typo/headline/Headline';
import { useKeyListener } from '../elements/KeyListener/hooks/useKeyListerner';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../theme/ComputedStyles/recoil/computedStyles.recoil';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../controls/button/IconButton';
import { Button } from '../controls/button/Button';
import { SkeletonLine } from '../elements/Skeletons/SkeletonLine';
import { CardWrapper } from '../../frontend/layout/Cards/CardWrapper';

export interface IEditorModal extends PropsWithChildren {
    show: boolean;
    closeFunction: () => void;
    headline?: string;
    controlBar?: React.ReactNode;
    testId?: string;
    tags?: React.ReactNode[];
    topControlBar?: ReactElement<unknown>[];
    closeable?: boolean;
    noAnimation?: boolean;
    noBackDrop?: boolean;
    width?: 'wide' | 'narrow';
    closeWithESC?: boolean;
}

export const EditorModal: React.FC<IEditorModal> = (props) => {
    const {
        show,
        children,
        closeFunction,
        headline,
        controlBar,
        testId,
        tags,
        topControlBar,
        closeable = true,
        noAnimation,
        noBackDrop,
        width = 'wide',
        closeWithESC = true,
    } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    useKeyListener('Escape', () => {
        if (closeWithESC) {
            closeFunction();
        }
    });

    const animationStyles = useMemo(() => {
        if (noAnimation) {
            return 'transform translate-x-0';
        }

        return 'transform translate-x-full';
    }, [noAnimation]);

    const widthClasses = useMemo((): string => {
        if (width === 'narrow') {
            return 'lg:max-w-[500px]';
        }
        return 'lg:max-w-[1150px]';
    }, [width]);

    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setShowChildren(true);
            }, 600);
        }

        if (!show) {
            setShowChildren(false);
        }
    }, [show]);

    return (
        <>
            {tenantTheme && (
                <>
                    {!noBackDrop && (
                        <Transition
                            as={'div'}
                            className="fixed top-0 left-0 z-50 flex h-screen w-screen bg-neutral-600/20 backdrop-blur-sm"
                            show={show ?? false}
                            appear={show ?? false}
                            enter="transition ease-out duration-500"
                            enterFrom="transform opacity-0"
                            enterTo="transform opacity-100"
                            leave="transition ease-in duration-300"
                            leaveFrom="transform opacity-100"
                            leaveTo="transform opacity-0"
                        />
                    )}
                    <Transition
                        as={'section'}
                        data-test-id={testId ?? 'admin-editor-modal'}
                        className={`fixed right-0 top-0 z-50 flex h-screen w-full max-w-full flex-col gap-y-1 border-l border-border bg-background-light shadow-neutral-600 drop-shadow-md ${widthClasses}`}
                        show={show ?? false}
                        appear={show ?? false}
                        enter="transition ease-in-out duration-300"
                        enterFrom={`transform ${animationStyles}`}
                        enterTo="transform opacity-100 translate-x-0"
                        leave="transition ease-out duration-100"
                        leaveFrom="transform opacity-100 translate-x-0"
                        leaveTo="transform opacity-0 translate-x-full"
                    >
                        {closeable && (
                            <div className="absolute top-3 -left-12 hidden lg:flex" id="test23">
                                <IconButton
                                    testId="admin-editor-modal-close"
                                    onClick={closeFunction}
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </IconButton>
                            </div>
                        )}
                        {headline && (
                            <div className="relative z-0 flex w-full flex-col gap-1 p-2 md:p-6 md:pr-12 lg:max-w-[calc(100%-50px)] lg:p-4">
                                <div className="relative flex w-full flex-row items-center gap-1">
                                    {closeable && (
                                        <IconButton
                                            outerCss="relative flex lg:hidden"
                                            testId="admin-editor-modal-close"
                                            onClick={closeFunction}
                                        >
                                            <XMarkIcon className="h-6 w-6" />
                                        </IconButton>
                                    )}
                                    <Headline headline={headline} type="h4-responsive" />
                                </div>

                                {tags && (
                                    <div className="flex flex-row gap-4">
                                        {tags.map(() => tags)}
                                    </div>
                                )}
                                {topControlBar && (
                                    <div className="relative mt-4 flex w-full flex-row gap-4">
                                        {topControlBar.map((item, index) => {
                                            return (
                                                <div key={`control-bar-item-${index}`}>{item}</div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="relative flex h-screen flex-col gap-1 overflow-y-auto p-4">
                            {showChildren && children}

                            {!showChildren && (
                                <CardWrapper>
                                    <SkeletonLine height={400} />
                                    <SkeletonLine height={400} />
                                    <SkeletonLine height={400} />
                                    <SkeletonLine height={400} />
                                </CardWrapper>
                            )}
                        </div>
                        {/* Static Button Bar */}
                        {controlBar && (
                            <div className="flex w-full flex-row justify-end gap-x-2 p-4 lg:gap-x-4">
                                <div className="grow self-start">
                                    {closeFunction && (
                                        <Button
                                            color="neutral"
                                            onClick={closeFunction}
                                            responsiveShrink={true}
                                            iconStart={<XMarkIcon />}
                                            testId="admin-editor-modal-close-button"
                                            isTranslatable={false}
                                        >
                                            Schließen
                                        </Button>
                                    )}
                                </div>
                                <div className="flex gap-4 self-end">{controlBar}</div>
                            </div>
                        )}
                    </Transition>
                </>
            )}
        </>
    );
};
